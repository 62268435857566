import { useEffect, useState, createContext, useContext } from 'react';
import { supabase } from '@/utils/supabase-client';
import { slugifyString, LogSnagPost, postData } from '@/utils/helpers';

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const [userLoaded, setUserLoaded] = useState(false);
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [team, setTeam] = useState(null);
  const [userFinderLoaded, setUserFinderLoaded] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);

  useEffect(() => {
    const session = supabase.auth.session();
    setSession(session);
    setUser(session?.user ?? null);
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setSession(session);
        setUser(session?.user ?? null);
      }
    );

    setUserFinderLoaded(true);

    return () => {
      authListener.unsubscribe();
    };
  }, []);
  const getTeam = () => supabase.from('teams').select('*').single();
  const getUserDetails = () =>
    supabase.from('users').select('*').eq('id', user?.id).single();
  const getSubscription = () =>
    supabase
      .from('subscriptions')
      .select('*, prices(*, products(*))')
      .in('status', ['trialing', 'active'])
      .single();

  useEffect(() => {
    if (user) {
      Promise.allSettled([getTeam(), getUserDetails(), getSubscription()]).then(
        (results) => {
          if (results[0].value.data !== null) {
            setTeam(results[0].value.data);
          } else {
            setTeam('none');
          }
          setUserDetails(results[1].value.data);
          setSubscription(results[2].value.data);

          if (results[2].value.data !== null) {
            setPlanDetails(
              results[2].value.data.prices.products.name.toLowerCase()
            );
          } else {
            setPlanDetails('free');
          }

          setUserLoaded(true);
          setUserFinderLoaded(true);
        }
      );
    }
  }, [user]);

  const value = {
    session,
    user,
    team,
    userDetails,
    userLoaded,
    subscription,
    userFinderLoaded,
    planDetails,
    signIn: (options) =>
      supabase.auth.signIn(
        { email: options.email },
        {
          shouldCreateUser: options.shouldCreateUser,
          redirectTo: options.redirectTo
        }
      ),
    signInWithPassword: (options) =>
      supabase.auth.signIn(
        { email: options.email, password: options.password },
        {
          shouldCreateUser: options.shouldCreateUser,
          redirectTo: options.redirectTo
        }
      ),
    signUp: (options) =>
      supabase.auth.signUp(
        { email: options.email, password: options.password },
        { redirectTo: options.redirectTo }
      ),
    forgotPassword: (email) => supabase.auth.api.resetPasswordForEmail(email),
    signOut: () => {
      setUserDetails(null);
      setSubscription(null);
      return supabase.auth.signOut();
    }
  };

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};

//Reset Password
export const resetPassword = async (token, password) => {
  const { error, data } = await supabase.auth.api.updateUser(token, {
    password: password
  });

  if (error) {
    return { error };
  } else {
    return { data };
  }
};

export const getCompanies = async (teamId) => {
  const { data, error } = await supabase
    .from('companies')
    .select('*')
    .eq('team_id', teamId);

  if (error) return error;
  return data;
};

export const getBrands = async (companyId) => {
  const { data, error } = await supabase
    .from('brands')
    .select('*')
    .eq('company_id', companyId)
    .order('created', { ascending: true });

  let brandsData = data;

  if (data) {
    // Update outer loop with a for...of loop
    for (const brand of data) {
      // Get campaigns for the current brand
      const campaignsQuery = await supabase
        .from('campaigns')
        .select('*', { count: 'exact' })
        .eq('brand_id', brand?.brand_id);

      let campaign_affiliate_count = 0;
      let commissionValue = 0;

      // Loop through campaigns
      if (campaignsQuery?.data) {
        for (const campaign of campaignsQuery.data) {
          // Get affiliate count and commission value for the current campaign
          const affiliateQuery = await supabase
            .from('affiliates')
            .select('campaign_id', { count: 'exact' })
            .eq('campaign_id', campaign.campaign_id);

          const commissionsQuery = await supabase
            .from('commissions')
            .select('commission_sale_value')
            .eq('campaign_id', campaign.campaign_id)
            .lt('commission_due_date', new Date().toISOString());

          campaign_affiliate_count += affiliateQuery?.count || 0;

          if (commissionsQuery?.data !== null) {
            commissionsQuery?.data?.forEach((commission) => {
              if (commission.commission_sale_value > 0) {
                commissionValue += commission.commission_sale_value;
              }
            });
          }
        }
      }

      brand['campaign_count'] = campaignsQuery?.count || 0;
      brand['affiliate_count'] = campaign_affiliate_count;
      brand['commissions_value'] = commissionValue > 0 ? commissionValue : 0;
    }
  }

  if (error) return error;
  return brandsData;
};

//Get user campaigns
export const getCampaigns = async (companyId) => {
  const { data, error } = await supabase
    .from('campaigns')
    .select(
      `
    *,
    brand:brand_id (brand_name, brand_currency, brand_id, brand_handle)
  `
    )
    .eq('company_id', companyId)
    .order('default_campaign', { ascending: false })
    .order('created', { ascending: false });

  if (data && data?.length > 0) {
    data?.map((item) => {
      if (item?.brand?.brand_currency === null) {
        item['brand']['brand_currency'] = 'USD';
      }
    });
  }

  let campaignsData = data;

  if (data) {
    await Promise.all(
      data?.map(async (item) => {
        let affiliateQuery = await supabase
          .from('affiliates')
          .select('campaign_id', { count: 'exact' })
          .eq('campaign_id', item?.campaign_id);

        item['affiliate_users'] = affiliateQuery?.data;
        item['affiliate_count'] = affiliateQuery?.count;

        let commissionsQuery = await supabase
          .from('commissions')
          .select('commission_sale_value')
          .eq('campaign_id', item?.campaign_id)
          .lt('commission_due_date', [new Date().toISOString()]);

        if (commissionsQuery?.data !== null) {
          let commissionValue = 0;
          commissionsQuery?.data?.map((commission) => {
            if (commission?.commission_sale_value > 0) {
              commissionValue =
                commissionValue + commission?.commission_sale_value;
            }
          });
          item['commissions_value'] = commissionValue > 0 ? commissionValue : 0;
        }
      })
    );
  }

  if (error) return error;
  return campaignsData;
};

//Get user campaigns
export const getAffiliates = async (companyId) => {
  const { data, error } = await supabase
    .from('affiliates')
    .select(
      `
    *,
    details:invited_user_id (email)
  `
    )
    .eq('company_id', companyId);

  let affiliatesData = data;

  if (data) {
    await Promise.all(
      data?.map(async (item) => {
        let commissionsQuery = await supabase
          .from('commissions')
          .select('commission_sale_value')
          .eq('affiliate_id', item?.affiliate_id)
          .lt('commission_due_date', [new Date().toISOString()]);

        if (commissionsQuery?.data !== null) {
          let commissionValue = 0;
          commissionsQuery?.data?.map((commission) => {
            if (commission?.commission_sale_value > 0) {
              commissionValue =
                commissionValue + commission?.commission_sale_value;
            }
          });
          item['commissions_value'] = commissionValue > 0 ? commissionValue : 0;
        }
      })
    );
  }

  if (error) return error;
  return affiliatesData;
};

//Get user referrals
export const getReferrals = async (companyId, date, page) => {
  let query = supabase
    .from('referrals')
    .select(
      `
        *,
        campaign:campaign_id (campaign_name),
        affiliate:affiliate_id (details:invited_user_id(email))
      `,
      { count: 'exact' }
    )
    .eq('company_id', companyId)
    .order('created', { ascending: false })
    .limit(30);

  if (date !== null) {
    query.lt('created', [date]);
  }

  if (page === 'visited-link') {
    query
      .is('referral_converted', false)
      .is('referral_reference_email', null)
      .gt('referral_expiry', [new Date().toISOString()]);
  }

  if (page === 'expired') {
    query
      .lt('referral_expiry', [new Date().toISOString()])
      .is('referral_reference_email', null)
      .is('referral_converted', false);
  }

  if (page === 'signed-up') {
    query
      .not('referral_reference_email', 'is', null)
      .is('referral_converted', false);
  }

  if (page === 'converted') {
    query.is('referral_converted', true);
  }

  const { data, count, error } = await query;

  if (error) return 'error';
  return { data, count };
};

//Get user referrals
export const getSales = async (companyId, date, page) => {
  let query = supabase
    .from('commissions')
    .select(
      `
        *,
        campaign:campaign_id (campaign_name),
        affiliate:affiliate_id (details:invited_user_id(email,paypal_email)),
        referral:referral_id (referral_reference_email)
      `,
      { count: 'exact' }
    )
    .eq('company_id', companyId)
    .order('created', { ascending: false })
    .limit(30);

  query.gt('commission_sale_value', 0);

  if (date !== null) {
    query.lt('created', [date]);
  }

  if (page === 'due') {
    query
      .lt('commission_due_date', [new Date().toISOString()])
      .is('paid_at', null);
  }

  if (page === 'paid') {
    query.not('paid_at', 'is', null);
  }

  if (page === 'pending') {
    query
      .gt('commission_due_date', [new Date().toISOString()])
      .is('paid_at', null);
  }

  const { data, count, error } = await query;

  if (error) return 'error';
  return { data, count };
};

export const getReflioCommissionsDue = async (teamId) => {
  let query = supabase
    .from('commissions')
    .select(
      `
        *,
        campaign:campaign_id (campaign_name),
        affiliate:affiliate_id (details:invited_user_id(email,paypal_email)),
        referral:referral_id (referral_reference_email)
      `,
      { count: 'exact' }
    )
    .eq('team_id', teamId)
    .eq('reflio_commission_paid', false)
    .lt('commission_due_date', [new Date().toISOString()])
    .is('paid_at', null)
    .order('created', { ascending: false });

  const { data, error } = await query;

  if (error) return 'error';
  return { data };
};

export const payCommissions = async (
  companyId,
  checkedCommissions,
  eligibleCommissions
) => {
  if (!companyId || !eligibleCommissions) return 'error';

  try {
    if (checkedCommissions.length === 0 || checkedCommissions === null) {
      await Promise.all(
        eligibleCommissions?.map(async (item) => {
          await supabase
            .from('commissions')
            .update({
              paid_at: new Date().toISOString()
            })
            .eq('commission_id', item?.commission_id);
        })
      );
    } else {
      await Promise.all(
        checkedCommissions?.map(async (item) => {
          await supabase
            .from('commissions')
            .update({
              paid_at: new Date().toISOString()
            })
            .eq('commission_id', item);
        })
      );
    }

    return 'success';
  } catch (error) {
    console.warn(error);
    return 'error';
  }
};

//Create company
export const newTeam = async (user, form) => {
  if (!form?.team_name) return 'error';

  const getTeam = await supabase.from('teams').select('*');

  if (getTeam?.data?.length > 0) {
    console.log('Team already exists');
    return 'error';
  }

  const { data } = await supabase.from('teams').insert({
    id: user?.id,
    team_name: form?.team_name
  });

  if (data && data[0]?.team_id) {
    const userUpdate = await supabase
      .from('users')
      .update({
        team_id: data[0]?.team_id
      })
      .eq('id', user?.id);

    if (userUpdate?.data && userUpdate?.data !== null) {
      return 'success';
    }
  }

  return 'error';
};

export const newCompany = async (user, form) => {
  if (!form?.company_handle || !form?.company_url || !form?.company_name)
    return 'error';

  const { data, error } = await supabase.from('companies').insert({
    id: user?.id,
    team_id: user?.team_id,
    company_name: form?.company_name,
    company_url: form?.company_url,
    company_handle: form?.company_handle,
    domain_verified: false
  });

  if (error) {
    if (error?.code === '23505') {
      return 'duplicate';
    }

    return 'error';
  } else {
    return data;
  }
};

export const newBrand = async (user, form, companyId) => {
  if (!form?.brand_handle || !form?.brand_url || !form?.brand_name)
    return 'error';

  const { data, error } = await supabase.from('brands').insert({
    id: user?.id,
    team_id: user?.team_id,
    company_id: companyId,
    brand_name: form?.brand_name,
    brand_url: form?.brand_url,
    brand_handle: form?.brand_handle,
    domain_verified: false
  });

  console.log('data', data);
  console.log('error', error);

  if (error) {
    if (error?.code === '23505') {
      return 'duplicate';
    }

    return 'error';
  } else {
    return 'success';
  }
};

export const editBrand = async (user, form, brandId) => {
  if (!form?.brand_handle || !form?.brand_url || !form?.brand_name)
    return 'error';

  const { error } = await supabase
    .from('brands')
    .update({
      brand_name: form?.brand_name,
      brand_url: form?.brand_url,
      brand_handle: form?.brand_handle
    })
    .eq('brand_id', brandId);

  if (error) {
    if (error?.code === '23505') {
      return 'duplicate';
    }

    return 'error';
  } else {
    return 'success';
  }
};

export const newBrandFromCompany = async (user, form, companyId) => {
  if (!form?.company_handle || !form?.company_url || !form?.company_name)
    return 'error';

  const { data, error } = await supabase.from('brands').insert({
    id: user?.id,
    team_id: user?.team_id,
    company_id: companyId,
    brand_name: form?.company_name,
    brand_handle: form?.company_handle,
    brand_url: form?.company_url
  });

  if (error) {
    if (error?.code === '23505') {
      return 'duplicate';
    }

    console.log('brand error:');
    console.log(error);

    return 'error';
  } else {
    return data;
  }
};

export const continueWithoutProcessor = async (brandId) => {
  let { error } = await supabase
    .from('brands')
    .update({
      payment_integration_type: 'manual'
    })
    .eq('brand_id', brandId);

  if (error) return 'error';

  return 'success';
};

export const handleActiveCompany = async (switchedCompany) => {
  let { data } = await supabase
    .from('companies')
    .select('*')
    .eq('active_company', true);

  if (data) {
    data?.map(async (company) => {
      await supabase
        .from('companies')
        .update({
          active_company: false
        })
        .eq('company_id', company?.company_id);
    });
  }

  let { error } = await supabase
    .from('companies')
    .update({
      active_company: true
    })
    .eq('company_id', switchedCompany);
  if (error) return 'error';

  return 'success';
};

export const newCampaign = async (user, form, companyId, brandId) => {
  let formFields = form;
  formFields.id = user?.id;
  formFields.company_id = companyId;
  formFields.brand_id = brandId;

  if (formFields.commission_value && formFields.commission_value <= 0) {
    formFields.commission_value = 20;
  }

  if (formFields.cookie_window && formFields.cookie_window <= 0) {
    formFields.cookie_window = 60;
  }

  if (formFields.commission_period && formFields.commission_period <= 0) {
    formFields.commission_period = 12;
  }

  if (formFields.minimum_days_payout && formFields.minimum_days_payout <= 30) {
    formFields.minimum_days_payout = 30;
  }

  if (formFields.default_campaign && formFields.default_campaign === true) {
    formFields.default_campaign = true;
  }

  if (!formFields?.team_id) {
    formFields.team_id = user?.team_id;
  }

  if (
    formFields.discount_code?.length === 0 ||
    formFields.discount_code === null
  ) {
    formFields.discount_code = null;
    formFields.discount_type = null;
    formFields.discount_value = null;
  }

  let { data } = await supabase
    .from('campaigns')
    .select('*')
    .eq('default_campaign', true)
    .eq('company_id', companyId);

  if (data?.length === 0) {
    formFields.default_campaign = true;
  }

  if (
    formFields.default_campaign &&
    formFields.default_campaign === true &&
    data?.length > 0
  ) {
    data?.map(async (campaign) => {
      const campaignEdit = await supabase
        .from('campaigns')
        .update({
          default_campaign: false
        })
        .eq('campaign_id', campaign?.campaign_id);
    });
  }

  const { error } = await supabase.from('campaigns').insert(formFields);

  if (error) return 'error';

  await LogSnagPost(
    'new-campaign',
    `New campaign created (${formFields.campaign_name}), for brand ${brandId}`
  );

  return 'success';
};

export const editCampaign = async (user, campaignId, formFields) => {
  //TO DO: FIX THIS DUPLICATE CODE FROM ABOVE ^
  if (formFields.commission_value && formFields.commission_value <= 0) {
    formFields.commission_value = 20;
  }

  if (formFields.cookie_window && formFields.cookie_window <= 0) {
    formFields.cookie_window = 60;
  }

  if (formFields.commission_period && formFields.commission_period <= 0) {
    formFields.commission_period = 12;
  }

  if (formFields.minimum_days_payout && formFields.minimum_days_payout <= 0) {
    formFields.minimum_days_payout = 30;
  }

  if (formFields.default_campaign) {
    formFields.default_campaign = true;
  }

  if (!formFields?.team_id) {
    formFields.team_id = user?.team_id;
  }

  if (formFields?.campaign_public) {
    formFields.campaign_public = true;
  } else {
    formFields.campaign_public = false;
  }

  if (
    formFields.discount_code?.length === 0 ||
    formFields.discount_code === null
  ) {
    formFields.discount_code = null;
    formFields.discount_type = null;
    formFields.discount_value = null;
  }

  if (formFields.default_campaign && formFields.default_campaign === true) {
    formFields.default_campaign = true;

    await supabase
      .from('campaigns')
      .update({
        default_campaign: false
      })
      .eq('default_campaign', true);

    const { error } = await supabase
      .from('campaigns')
      .update(formFields)
      .eq('campaign_id', campaignId);

    if (error) return 'error';

    return 'success';
  } else {
    const { error } = await supabase
      .from('campaigns')
      .update(formFields)
      .eq('campaign_id', campaignId);

    if (error) return 'error';

    return 'success';
  }
};

export const editCampaignMeta = async (campaignId, metaData) => {
  if (!campaignId || !metaData) return 'error';

  const { error } = await supabase
    .from('campaigns')
    .update({
      custom_campaign_data: metaData
    })
    .eq('campaign_id', campaignId);

  if (error) return 'error';

  return 'success';
};

//New Stripe Account
export const newStripeAccount = async (stripeId, brandId) => {
  const getAccountDetails = await fetch('/api/get-account-details', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      accountId: stripeId
    })
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return data;
    });

  const { error } = await supabase
    .from('brands')
    .update({
      payment_integration_type: 'stripe',
      payment_integration_field_one: stripeId,
      payment_integration_data: getAccountDetails?.data ?? null
    })
    .eq('brand_id', brandId);

  if (error) {
    console.log('Error:');
    console.log(error);
    return 'error';
  } else {
    await LogSnagPost(
      'stripe-connected',
      `New Stripe account connected for brand ${brandId}`
    );
    return 'success';
  }
};

//Deletes stripe ID from company account
export const removePaymentIntegration = async (brandId) => {
  const { error } = await supabase
    .from('brands')
    .update({
      payment_integration_type: null,
      payment_integration_field_one: null,
      payment_integration_field_two: null,
      payment_integration_field_three: null,
      payment_integration_data: null
    })
    .eq('brand_id', brandId);
  if (error) return 'error';

  return 'success';
};

export const addPaymentIntegration = async (
  session,
  brandId,
  paymentType,
  formData
) => {
  if (!paymentType || !formData) return 'error';

  if (paymentType === 'paddle') {
    const verifyCall = await postData({
      url: `/api/payments/paddle/${brandId}/verify`,
      data: {
        vendorId: formData?.payment_integration_field_three,
        apiKey: formData?.payment_integration_field_two
      },
      token: session.access_token
    });

    const cryptoCall = await postData({
      url: `/api/team/crypto`,
      data: {
        cryptoType: 'encrypt',
        cryptoArray: [
          formData?.payment_integration_field_one,
          formData?.payment_integration_field_two,
          formData?.payment_integration_field_three
        ]
      },
      token: session.access_token
    });

    if (
      verifyCall?.message === 'success' &&
      cryptoCall?.message === 'success' &&
      cryptoCall?.data?.length
    ) {
      const { error } = await supabase
        .from('brands')
        .update({
          payment_integration_type: paymentType,
          payment_integration_field_one: cryptoCall?.data[0],
          payment_integration_field_two: cryptoCall?.data[1],
          payment_integration_field_three: cryptoCall?.data[2]
        })
        .eq('brand_id', brandId);

      if (!error) {
        await LogSnagPost(
          'paddle-connected',
          `New Paddle account connected for brand ${brandId}`
        );
        return 'success';
      }
    }
  }

  return 'error';
};

export const manuallyVerifyDomain = async (brandId) => {
  let { error } = await supabase
    .from('brands')
    .update({
      domain_verified: true
    })
    .eq('brand_id', brandId);

  if (error) return 'error';

  return 'success';
};

const deleteReferralsAndCommissions = async (affiliateId) => {
  await supabase
    .from('referrals')
    .delete()
    .match({ affiliate_id: affiliateId });

  await supabase
    .from('commissions')
    .delete()
    .match({ affiliate_id: affiliateId });

  return 'success';
};

export const deleteAffiliate = async (id) => {
  const deleteStatus = await deleteReferralsAndCommissions(id);

  if (deleteStatus === 'success') {
    const { error } = await supabase
      .from('affiliates')
      .delete()
      .match({ affiliate_id: id });

    if (error) {
      return 'error';
    } else {
      return 'success';
    }
  }
};

export const deleteReferral = async (id) => {
  const deleteAllCommissions = await supabase
    .from('commissions')
    .delete()
    .match({ referral_id: id });

  if (deleteAllCommissions) {
    const { error } = await supabase
      .from('referrals')
      .delete()
      .match({ referral_id: id });

    if (error) {
      return 'error';
    } else {
      return 'success';
    }
  }

  return 'error';
};

export const deleteCommission = async (id) => {
  const { error } = await supabase
    .from('commissions')
    .delete()
    .match({ commission_id: id });

  if (error) {
    return 'error';
  } else {
    return 'success';
  }
};

export const deleteCompany = async (id) => {
  const { error } = await supabase
    .from('companies')
    .delete()
    .match({ company_id: id });

  if (error) {
    return 'error';
  } else {
    return 'success';
  }
};

export const deleteCampaign = async (id) => {
  const { error } = await supabase
    .from('campaigns')
    .delete()
    .match({ campaign_id: id });

  if (error) {
    return 'error';
  } else {
    return 'success';
  }
};

export const editCurrency = async (brandId, data) => {
  if (!data?.brand_currency) {
    return 'error';
  }

  const { error } = await supabase
    .from('brands')
    .update({
      brand_currency: data?.brand_currency
    })
    .eq('brand_id', brandId);

  if (error) return 'error';

  return 'success';
};

export const editCompanyWebsite = async (id, form) => {
  const { error } = await supabase
    .from('companies')
    .update({
      company_url: form?.company_url,
      domain_verified: false
    })
    .match({ company_id: id });

  if (error) {
    return 'error';
  } else {
    return 'success';
  }
};

export const editCompanyHandle = async (id, form) => {
  if (!form?.company_handle) return 'error';

  const { error } = await supabase
    .from('companies')
    .update({
      company_handle: slugifyString(form?.company_handle)
    })
    .match({ company_id: id });

  if (error) {
    if (error?.code === '23505') {
      return 'duplicate';
    }

    return 'error';
  } else {
    return 'success';
  }
};

export const disableEmails = async (id, type) => {
  const { error } = await supabase
    .from('companies')
    .update({ disable_emails: type })
    .match({ company_id: id });

  if (error) {
    return 'error';
  } else {
    return 'success';
  }
};

export const archiveSubmission = async (id, type) => {
  const { error } = await supabase
    .from('submissions')
    .update({ archived: type })
    .match({ submission_id: id });

  if (error) {
    return 'error';
  } else {
    return 'success';
  }
};

//Upload logo to log
export const uploadLogoImage = async (companyId, file) => {
  const modifiedId = companyId?.replace('_', '-');
  const { data, error } = await supabase.storage
    .from('company-assets')
    .upload(`${modifiedId}.${file.name}`, file, {
      cacheControl: '0',
      upsert: true
    });

  if (error) return error;

  if (data?.Key) {
    const { error } = await supabase
      .from('companies')
      .update({
        company_image: data?.Key
      })
      .eq('company_id', companyId);

    if (error) return error;
  }

  return data;
};

export const uploadBrandLogoImage = async (brandId, file) => {
  const modifiedId = brandId?.replace('_', '-');
  const { data, error } = await supabase.storage
    .from('brand-assets')
    .upload(`${modifiedId}.${file.name}`, file, {
      cacheControl: '0',
      upsert: true
    });

  if (error) return error;

  if (data?.Key) {
    const { error } = await supabase
      .from('brands')
      .update({
        brand_image: data?.Key
      })
      .eq('brand_id', brandId);

    console.log('error:');
    console.log(error);

    if (error) return error;
  }

  return data;
};

export const getAnalytics = async (companyId, campaignId, dateFrom, dateTo) => {
  let campaigns = supabase
    .from('campaigns')
    .select('*')
    .eq('company_id', companyId);

  if (campaignId) {
    campaigns.eq('campaignId', campaignId);
  }

  let campaignsData = await campaigns?.data;

  if (campaignsData) {
    await Promise.all(
      data?.map(async (item) => {
        let affiliateQuery = supabase
          .from('affiliates')
          .select('campaign_id, created, accepted', { count: 'exact' })
          .eq('campaign_id', item?.campaign_id)
          .eq('accepted', true)
          .eq('campaign_id', item?.campaign_id);

        let referralsQuery = supabase
          .from('referrals')
          .select(
            'created, campaign_id, referral_reference_email, referral_converted'
          )
          .eq('campaign_id', item?.campaign_id);

        let commissionsQuery = supabase
          .from('commissions')
          .select('commission_sale_value, created, campaign_id')
          .eq('campaign_id', item?.campaign_id);

        if (dateFrom) {
          affiliateQuery.lt('created', [dateFrom]);
          referralsQuery.lt('created', [dateFrom]);
          commissionsQuery.lt('created', [dateFrom]);
        }

        if (dateTo) {
          affiliateQuery.gt('created', [dateTo]);
          referralsQuery.lt('created', [dateTo]);
          commissionsQuery.lt('created', [dateTo]);
        }

        item.affiliates = await affiliateQuery?.data;
        item.referrals = await referralsQuery?.data;
        item.commissions = await commissionsQuery?.data;
      })
    );
  }

  if (error) return error;
  return campaignsData;
};
